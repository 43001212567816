<template>
  <div class="container has-background-white-ter">
    <section class="section is-primary pb-1">
      <div class="container" style="padding-bottom: 1em">
        <div
          class="box"
          style="
            align-content: flex-start;
            flex-direction: row;
            justify-content: space-between;
            display: flex;
          "
        >
          <h4 class="title2">
            <router-link to="/school/links/">Useful Links</router-link>
          </h4>

          <div v-if="isLevel25" class="field has-addons is-pulled-right">
            <router-link
              type="button"
              class="button is-primary"
              to="/school/links/manage/"
              >Manage</router-link
            >
          </div>
        </div>
      </div>

      <div class="tile is-ancestor" v-for="(lb, i) in allLinks" :key="i">
        <div class="tile is-parent" v-for="(lbb, j) in lb" :key="j">
          <article class="tile is-child box">
            <p class="title">{{ lbb.name }}</p>

            <div class="content">
              <p>
                {{ lbb.notes }}
              </p>
              <div style="margins: auto; text-align: right">
                <a
                  :href="lbb.link"
                  target="_blank"
                  class="button is-outlined is-dark"
                  >more</a
                >
              </div>
            </div>
          </article>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
//import firebase from 'firebase/compat/app';
//import 'firebase/compat/auth';

//import { toast } from "bulma-toast";
//import { format } from "date-fns";
//import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
//import download from "downloadjs";
//import LunchOption from "./LunchOption.vue";

export default {
  name: "home",

  components: {
    //  LunchOption,
  },

  data: function () {
    return {
      links: [],
    };
  },

  computed: {
    isLevel25: function () {
      return this.$store.getters.isAccessLevel(25);
    },

    allLinks() {
      if (this.links.length < 1) {
        return [];
      }

      const res = [];

      for (let i = 0; i < this.links.length; i += 3) {
        res.push(this.links.slice(i, i + 3));
      }
      console.log(res);
      return res;
    },
  },
  methods: {},

  async mounted() {
    this.$api.get("v2/staff/links/", true, true).then((resp) => {
      this.links = resp;
    });
  },
};
</script>

<style>
a.router-link-exact-active {
  color: inherit;
}

div.box > div.field {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
